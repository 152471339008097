<template>
  <section>
    <Container>
      <h2 class="py-8 text-3xl font-medium text-center text-white lg:text-4xl lg:text-left">
        {{ title }}
      </h2>

      <div class="grid grid-cols-1 gap-8 pt-4 pb-16 md:grid-cols-2 lg:grid-cols-3 lg:pb-24">
        <ButtonSocial v-for="(link, i) in social"
                      :key="i"
                      :icon="(link.icon as SocialType)"
                      :href="link.url"
                      :label="`Outfit7 @ ${link.title}`"
                      @click="handleClick(link.title)"
        />
      </div>
    </Container>
  </section>
</template>

<script setup lang="ts">
/**
 * List of social links with a title
 *
 * Social links are loaded from the social navigation menu, so they
 * are consistent across the site.
 */
import { useRoute } from 'vue-router'
import social from '../../content/navigation/social.json'
import type { SocialType } from '../../types/button'
import { useNuxtApp } from '#imports'

interface SocialLinksProps {
  title: string
}

defineProps<SocialLinksProps>()

const { $event } = useNuxtApp()
const route = useRoute()

function handleClick(linkTitle: string) {
  if (route.path.includes('contact-us')) return $event('Link', { props: { 'Contact us page': linkTitle } })
  if (route.path.includes('our-work')) return $event('Link', { props: { 'Our work page': linkTitle } })
}
</script>
